import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import Page from "../components/Page";

const LegalNotice = () => {
  const { t } = useTranslation();

  return (
    <Page title="Legal notice" description="Legal notice">
      <Container>
        <section className="section" id="legal-notice">
          <h3>{t("legal-section.legal-notice-page.title")}</h3>

          <p>{t("legal-section.legal-notice-page.introduction")}</p>

          <h6>{t("legal-section.legal-notice-page.owner-title")}</h6>

          <p>{t("legal-section.legal-notice-page.owner-line1")}</p>
          <p>{t("legal-section.legal-notice-page.owner-line2")}</p>
          <p>{t("legal-section.legal-notice-page.owner-line3")}</p>
          <p>{t("legal-section.legal-notice-page.owner-line4")}</p>
          <p>{t("legal-section.legal-notice-page.owner-line5")}</p>
          <p>{t("legal-section.legal-notice-page.owner-line6")}</p>
          <p>{t("legal-section.legal-notice-page.owner-line7")}</p>

          <h6>{t("legal-section.legal-notice-page.terms-title")}</h6>

          <p>{t("legal-section.legal-notice-page.terms-line1")}</p>
          <ul>
            <li>{t("legal-section.legal-notice-page.terms-line2")}</li>
            <li>{t("legal-section.legal-notice-page.terms-line3")}</li>
            <li>{t("legal-section.legal-notice-page.terms-line4")}</li>
            <li>{t("legal-section.legal-notice-page.terms-line5")}</li>
            <li>{t("legal-section.legal-notice-page.terms-line6")}</li>
            <li>{t("legal-section.legal-notice-page.terms-line7")}</li>
            <li>{t("legal-section.legal-notice-page.terms-line8")}</li>
          </ul>
          <p>{t("legal-section.legal-notice-page.terms-line9")}</p>
          <p>{t("legal-section.legal-notice-page.terms-line10")}</p>

          <h6>{t("legal-section.legal-notice-page.modifications-title")}</h6>

          <p>{t("legal-section.legal-notice-page.modifications-line1")}</p>

          <h6>{t("legal-section.legal-notice-page.links-title")}</h6>

          <p>{t("legal-section.legal-notice-page.links-line1")}</p>

          <h6>{t("legal-section.legal-notice-page.intellectual-title")}</h6>

          <p>{t("legal-section.legal-notice-page.intellectual-line1")}</p>
          <p>{t("legal-section.legal-notice-page.intellectual-line2")}</p>

          <h6>{t("legal-section.legal-notice-page.notifications-title")}</h6>

          <p>{t("legal-section.legal-notice-page.notifications-line1")}</p>

          <h6>{t("legal-section.legal-notice-page.applicable-title")}</h6>

          <p>{t("legal-section.legal-notice-page.applicable-line1")}</p>
        </section>
      </Container>
    </Page>
  );
};

export default LegalNotice;
